<!--
Copyright © MindMaze Group SA 2021.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-container">
                <img id="logo-img" alt="logo"
                     src="@/assets/images/logo-big.png" />
                  <div v-show="!showResetInstructions" class="form-group">
                        The new password will need:
                        <ul>
                          <li>At least eight characters</li>
                          <li>At least one upper case English letter</li>
                          <li>At least one lower case English letter</li>
                          <li>At least one digit</li>
                          <li>At least one special character from this list: #?!@$%^&*-{}~</li>
                        </ul> 
                  </div>
                <form v-show="!showResetInstructions" name="form" @submit.prevent="handleLogin">
                    <div class="form-group">
                        <label for="password1">Password</label>
                        <input v-model="password"
                               ref="password"
                               v-validate="'required|regex:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\\-{}~])|min:8'"
                               type="password"
                               class="form-control"
                               name="password1" />
                        <div v-if="errors.has('password1')"
                             class="alert alert-danger"
                             role="alert">The Password doesn't match the previous rules!</div>
                    </div>
                    <div class="form-group">
                        <label for="password2">Password confirmation</label>
                        <input v-validate="'required|confirmed:password'"
                               type="password"
                               class="form-control"
                               name="password2" />
                        <div v-if="errors.has('password2')"
                             class="alert alert-danger"
                             role="alert">Both passwords must be the same!</div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Change Password</span>
                        </button>
                    </div>
                    <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                    </div>
                </form>
                <div v-show="showResetInstructions" class="form-group">
                        You have changed your password. 
                        Now you can go back and login with your new credentials.
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ApiService from '@/services/api.service';

export default {
  name: 'ChangePassword',
  
  data() {
    return {
      password: '',
      loading: false,
      showResetInstructions: '',
      message: ''
    };
  },
  computed: {
    ...mapGetters('auth',['computeHome', 'loggedIn'])
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        let token = this.$route.query.token
        if (token) {
         ApiService.resetPassword(token, this.password).then(
            () => {
              this.showResetInstructions = true
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        } else {
          this.message = 'Invalid URL'
        }
      });
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: auto;
    margin-top: 8%;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-group {
    margin-top: 20px;
}
</style>
